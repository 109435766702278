




























import Vue from "vue";

export default Vue.extend({
  name: "Shorts",
  data() {
    return {
      url: '',
      slug: '',
      alias: '',
    };
  },
  methods: {
    createShort() {
      this.axios.post('short/create', {
        url: this.url,
        slug: this.slug,
        alias: this.alias,
      }, { withCredentials: true }).then(() => {
        this.url = '';
        this.slug = '';
        this.alias = '';
      });
    }
  },
});
